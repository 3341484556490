@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@import url('./page.css');
@import url('./table.css');
@import url('./kanban.css');
@import url('./form.css');
@import url('./button.css');
@import url('./react-select.css');
@import url('./react-horizontal-scrolling-menu.css');
/* @import url('./calendar.css'); */

:root{
    @apply font-poppins
}

.btn:disabled {
    cursor: not-allowed !important;
}
.otp-resend-btn{
    @apply text-blue-700 cursor-pointer hover:underline ml-1 disabled:cursor-not-allowed disabled:opacity-50 disabled:no-underline
}

.btn-outline--primary{
    @apply text-[#5B73E8] border border-[#5B73E8] rounded-[4px] px-10 py-2 w-full
}

.custom-toast-class{
    background: #000000;
    color: #ffffff;
    border-radius: 6px;
}

.custom-error-toast-class ul{
    list-style-type: disc;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 18px;
}

.custom-error-toast-class li{
    margin-bottom: 10px;
}

.btn-md{
    @apply !px-5 !py-[7px]
}

.btn-primary-1 {
    border: 1px solid #5B73E8;
    @apply px-10 py-2 rounded-[4px] text-white bg-[#5B73E8] disabled:opacity-50 disabled:cursor-not-allowed
}

.btn-primary-2 {
    border: 1px solid #5B73E8;
    @apply px-10 py-2 rounded-[4px] bg-white text-[#5B73E8] disabled:opacity-50 disabled:cursor-not-allowed
}

.btn-1{
    @apply !px-5 !py-[7px]
}


/* background while auto suggation */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    /* -webkit-text-fill-color: --bs-dark; */
    -webkit-box-shadow: 0 0 0 30px rgba(var(--bs-white-rgb), 1) inset !important;
    box-shadow: 0 0 0 30px rgba(var(--bs-white-rgb), 1) inset !important;
}


.tw-nav-tabs .nav-item .nav-link.active {
    @apply text-[#495057] font-bold !important
}

.tw-nav-tabs .nav-item .nav-link.active img {
    @apply opacity-100 !important
}

.tw-nav-tabs.nav-tabs > li > a {
    @apply text-[#B5B5C3] font-semibold text-sm px-1
}
.tw-nav-tabs.nav-tabs > li > a img {
    @apply opacity-50
}

.tw-nav-tabs .nav-item .nav-link::after{
    @apply bg-[#495057] !important
}

.tw-badge {
    @apply rounded text-center px-4 py-2 font-medium
}

.tw-badge-danger {
    @apply  text-[#F46A6A] bg-[#F46A6A] bg-opacity-10 !important;
}

.tw-badge-warning {
    @apply  text-[#F1B44C] bg-[#F1B44C] bg-opacity-10 !important;
}

.btn {
    @apply disabled:cursor-not-allowed !important;
}

.list-wrapper {
    @apply m-0 divide-y
}

.list-wrapper.list-item{
    @apply py-3 px-4
}

.list-wrapper.list-item.list-item-label{
    @apply font-normal text-[#74788D] text-sm
}

.list-wrapper.list-item.list-item-value{
    @apply text-[#495057] font-bold text-sm
}

.tw-nav-tabs-1.nav-tabs > li > a {
    @apply px-2 pb-4 !font-bold text-base text-[#74788D]
}

.tw-nav-tabs-1{
    @apply border-white !important;
}



@layer components {
    .client-calendar .client-calendar-item .active{
        @apply bg-[#5b73e8] !important;
    }

    .select-dropdown__menu{ 
    @apply overflow-auto scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-track-gray-100 !important
}
}

#client-invitation-popup h2{
    @apply text-lg !important
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
    background-color: white !important;
}

.popup-overlay {
    z-index: 9999 !important;
}

.react-confirm-alert-overlay {
    z-index: 9999 !important;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}
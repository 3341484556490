.document-package {
	@apply w-full;
}
.document-package tbody tr {
	@apply h-[48px] border-b border-white border-b-gray-100 bg-white px-3 py-2 transition-all hover:bg-[#F5F7FE] hover:shadow-[0px_2px_4px_0px_rgba(15,34,58,0.12)];
}

.document-package tbody tr td {
	@apply px-2.5 py-2.5;
}

.document-package thead tr th {
	@apply px-2.5 py-2.5;
}

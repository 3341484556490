.react-kanban-board{
    @apply  !p-0
}

.react-kanban-column{
    @apply shadow !px-1
}

.react-kanban-card{
    @apply rounded
}

.react-kanban-column-header span{
    @apply text-sm font-semibold !important
}
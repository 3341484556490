
.invalid-select-dropdown__control{
    @apply rounded !border !border-[#f46a6a]
}
.select-dropdown__control{
    @apply rounded !border border-[#CED4DA]    
}
.invalid-select-dropdown__placeholder, .select-dropdown__placeholder{
    @apply !text-[#B5B5C3]
}


.invalid-select-dropdown__indicator{
    @apply !text-[#f46a6a]
}

.invalid-select-dropdown__control--is-focused{
    @apply hover:border-red-400 !border-red-500 !shadow-none
}
.select-dropdown__control--is-focused{
    @apply hover:border-gray-400 !border-gray-300 !shadow-none
}

.invalid-select-dropdown__indicator-separator,
.select-dropdown__indicator-separator{
    width: 0 !important;
}

.invalid-select-dropdown__menu,.select-dropdown__menu{
    border-radius: 4px;
    background: #FFF;
    box-shadow: 2px 4px 8px 1px rgba(73, 80, 87, 0.14);
    z-index: 9999 !important;
    @apply !border-0 !p-0
}

.invalid-select-dropdown__menu-list,.select-dropdown__menu-list{    
    @apply !py-1 m-0 !rounded-none
}

.invalid-select-dropdown__menu-list::-webkit-scrollbar,.select-dropdown__menu-list::-webkit-scrollbar {
  height: 3px; /* height of the scrollbar */
  width: 3px; /* Width of the scrollbar */
}

.invalid-select-dropdown__menu-list::-webkit-scrollbar-thumb,.select-dropdown__menu-list::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the thumb */
  border-radius: 6px; /* Rounded corners */
}

.invalid-select-dropdown__menu-list::-webkit-scrollbar-thumb:hover,.select-dropdown__menu-list::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the thumb on hover */
}

.invalid-select-dropdown__menu-list::-webkit-scrollbar-track:hover,.select-dropdown__menu-list::-webkit-scrollbar-track:hover {
  background-color: #f0f0f0; /* Color of the track on hover */
}

.invalid-select-dropdown__menu-list::-webkit-scrollbar-track:active,.select-dropdown__menu-list::-webkit-scrollbar-track:active {
  background-color: #ddd; /* Color of the track on active */
}

.invalid-select-dropdown__option,.select-dropdown__option{    
    @apply hover:bg-gray-100  hover:text-gray-800 !cursor-pointer !rounded-none  font-normal not-italic leading-4 text-[#495057] transition-all border-l-2 border-transparent hover:border-main
}

.invalid-select-dropdown__option--is-selected,.select-dropdown__option--is-selected{
    @apply !bg-gray-200 !text-gray-800 border-l-2 border-main
}

.invalid-select-dropdown__option--is-focused, .select-dropdown__option--is-focused {
     @apply hover:bg-gray-100  hover:text-gray-800 cursor-pointer
}

.invalid-select-dropdown__multi-value,.select-dropdown__multi-value{
    border-radius: 4px !important;
    border: 1px solid #F5F6F8 !important;
    background: #F5F6F8 !important;
    @apply !flex !flex-row-reverse
}


.select-dropdown-container .select-dropdown--is-disabled{
    @apply !cursor-not-allowed
}

.select-dropdown-indicator {
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
}
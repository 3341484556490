/*
Template Name: Minible - Admin & Dashboard Template
Author: Themesbrand
Version: 2.1.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";
@import "placeholder.scss";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/alerts";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";


// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";;
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/repeater";
@import "custom/plugins/form-editors";
@import "custom/plugins/xeditable";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/responsive-table";
@import "custom/plugins/datatable";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/chat";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";

:root {
    font-family: 'IBM Plex Sans', sans-serif !important;
    font-feature-settings: 'clig' off, 'liga' off; 
}

.main-content{
    overflow: auto;
}
.card-header{
    background: #fff;
    border-bottom: 1px #e4e4e5 solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-title{
    margin: 0px 0px 0px 0px;
    font-size: 19px;
}
.team-dropdown-btn{
    display: block;
    padding: 0.6rem 1.1rem;
    color: #7b8190;
    position: relative;
    font-size: 15px;
    font-weight: 500;
    transition: all .4s;
    margin: 0 10px;
    border-radius: 3px;
    cursor: pointer;
}
.team-dropdown-btn i {
    display: inline-block;
    min-width: 1.5rem;
    padding-bottom: 0.125em;
    font-size: 1.05rem;
    line-height: 1.40625rem;
    vertical-align: middle;
    color: #7b8190;
    transition: all .4s;
}
.team-role {
    font-weight: 300;
    font-size: 12px;
}
.team-dropdown {
    word-break: break-word;
    white-space: normal;
}
.team-dropdown span .text-muted{
    font-size: 12px;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: greenyellow;
}
.nav-tabs-custom .nav-item{
    cursor: pointer;
}
.note-box-dashboard{
    min-height: 172px;    
}
.note-box{
    min-height: 220px;    
}
.bg-primary .note-box-body {
    color: #fff !important;
}
.bg-primary .text-muted{
    color: #fff !important;
}
.bg-info {
    color: #fff !important;
}

.note-box-body{
    display: flex;    
    flex-direction: column;
    justify-content: space-between;
}
.note-box-footer span.text-muted{
    font-size: 13px !important;
    color: #939393 !important;
}
.visibility{
    font-size: 13px;
    color: #939393;
}
.tooltip-inner {
    background-color: #000 !important;
    color: #fff !important;
    border: 1px solid #b9b9b9 !important;
    text-align: left !important;
}
.tooltip{
    background-color: transparent !important;
    border: 0 !important;
}
.form-wrap.form-builder .stage-wrap.empty{
    min-height: 100px !important;
}
.stage-wrap li {
    border: 1px solid #e4e4e5;
}
.step-box {
    border: 1px #e4e4e5 solid;
    border-radius: 4px;
    padding: 15px;
    margin-top: 10px;
}
.text-right{
    text-align: right;
}
.checkbox-group .form-control,
.radio-group .form-control,
.checkbox .form-control {
  -webkit-appearance: auto !important;
  appearance: auto !important;
  height: auto;
  width: auto !important;
  display: inline !important;
  margin-right: 4px !important;
  
  &:checked {
    box-shadow: none !important;
  }
  &:focus {
    box-shadow: none !important;
  }
  &:active{
    box-shadow: none !important;
  }
}
.form-elements .form-group{
    margin-bottom: 10px;
}
.rendered-form .tooltip-element{
    background-color: #cecece !important;
    color: #616161 !important;
}
.rendered-form [tooltip]:hover::after {
    background-color: #cecece !important;
    color: #616161 !important;    
    text-align: left !important;
}
.rendered-form [tooltip]:hover::before{
    border-color: #cecece transparent;
}
h5.header{
    border-bottom: 2px #898989 solid;
    padding-bottom: 10px;
}

.steps-btn{
    display: block;
    text-align: right;
    padding-left: 0;
}

.steps-btn li{
    display: inline-block;
    margin: 0 0.5em;
}
.steps-btn li .previous .disabled{
    opacity: .65;
    background-color: #5b73e8;
    color: #fff;
    cursor: not-allowed;
}

input.form-control{
    height: 38px;
}
.list-none{
    list-style: none;
}
.font-size-xl{
    font-size: 20px;
} 
.cursor-pointer{
    cursor: pointer;
}
.cursor-not-allowed{
    cursor: not-allowed;
}
.flex-col{
    display: flex;
    flex-direction: column;
}
.profile-icon-box{
    object-fit: cover;
    height: 100px !important;
    width: 100px !important;   
    border: 1px #dbdbdb solid;
    padding: 3px;
}
.p-3-5{
    padding: 20px;
}
.table-border-0 th td{
    border :0;
}

.link:hover{
    text-decoration: underline !important;
}
.vertical-middle{
    vertical-align: middle !important;
}
.w-f-content{
    width: fit-content !important;
}
.my-3{
    margin-top: 24px !important;
    margin-bottom: 24px  !important;
}
.invisibleInput{
    border-color:transparent !important;
}
.fontBold{
    font-weight: bold;
}
.border-hover {
    border: 1px solid #fff !important;
}
.border-hover:hover {
    border: 1px solid #f5f6f8 !important;
}
.stage-wrap.pull-left{
    width: 100% !important;
}
.form-wrap.form-builder .cb-wrap{
    width: 0% !important;
    display: none;
}
.ml-4{
    margin-left: 20px !important;
}
.soft-notification-container{
    position: fixed;
    top: 0;
    z-index: 9991;
    margin: auto;
    width: 100%;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
}
.soft-notification-box{
    border: 1px #ddfdca solid;
    background: #f0fbe9;
    padding: 7px 20px;
    border-radius: 3px;
}

.border2 {
    border: 1px solid #cbcbcb !important;
}
.w-fill{
    width: -webkit-fill-available;
}
.float-right{
    float: right;
}
.avatar-sm{
    object-fit: cover !important;
}
.client-workflow-dropzone{
    min-height: 160px !important;
}
.active-doc{
    font-weight: bold;   
}
.react-confirm-alert-overlay{
    background: #0000005c !important;
}
.confirm-wrapper{
background: #fff;
    padding: 15px 30px;
    border-radius: 5px;
}
.swal2-html-container p{
    font-size: 15px;
}

.was-validated .form-control:invalid, .form-control.is-invalid{
    background-image:none !important;
}

.form-elements select{
    appearance: auto;
}
.form-wrap.form-builder .form-control{
    appearance: auto !important;
}
.min-h-screen{
    min-height: 100vh;
}

.onboarding-container{
    // min-height: 100vh;
    background-color: white;
}

.onboarding-logo{
    margin: 5px 25px;
}

.step1-container{
    margin: auto;
}

.step1-container h3{
    font-weight: 600;
    min-width: 384px;
}

.line{
    border-top: 1px #bbbbbb solid;
    width: 38%;
}
.workflows-outstandings{
    @apply w-full
}
.workflows-outstandings tbody tr {
    @apply h-[48px] cursor-pointer border-b border-white bg-[#DEE5FF] px-3 py-2 transition-all hover:bg-[#F5F7FE] hover:shadow-[0px_2px_4px_0px_rgba(15,34,58,0.12)]
}

.workflows-outstandings tbody tr td {
    @apply  px-2.5 py-2.5
}

.workflows-outstandings thead tr th {
    @apply  px-2.5 py-2.5
}
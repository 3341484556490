.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: 0.5;
  background-color: #9D9FAC;
  &.btn::before {
    display: inline-block;
    content: "";
  }
}
/* Sizing */
.placeholder-xs {
  min-height: 0.6em;
}
.placeholder-sm {
  min-height: 0.8em;
}
.placeholder-lg {
  min-height: 1.2em;
}
/* Animation */
.placeholder-glow {
  .placeholder {
    animation: placeholder-glow 2s ease-in-out infinite;
    display: block;
    width: 100%;
  }
}
@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(
    130deg,
    black 55%,
    rgba(0, 0, 0, (1 - 0.5)) 75%,
    black 95%
  );
  mask-image: linear-gradient(
    130deg,
    black 55%,
    rgba(0, 0, 0, (1 - 0.5)) 75%,
    black 95%
  );
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}
@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}
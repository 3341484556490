.button {
    @apply px-3 !py-[6px] !border rounded
}

.button-primary {
    @apply bg-main  text-white border-main
}

.btn-primary-outline {
    @apply text-main border-main rounded hover:bg-main/10 active:bg-main/20  transition-all
}
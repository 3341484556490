.react-calendar {
    width: 320px;
    max-width: 100%;
    @apply !border border-gray-100 rounded-md !font-ibmplex shadow-md bg-white p-2;
}
.react-calendar--doubleView {
    width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    box-sizing: border-box;
}
.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    display: flex;
    height: 44px;
    @apply mb-2;
}
.react-calendar__navigation button {
    min-width: 44px;
    /* background: none; */
    @apply rounded-md text-sm;
}
.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
    @apply cursor-not-allowed;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}
.react-calendar__month-view__weekdays__weekday {
    @apply p-2 font-normal text-gray-500 normal-case;
}
.react-calendar__month-view__weekdays__weekday abbr {
    @apply normal-case;
}
abbr:where([title]) {
    text-decoration: none;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
    @apply text-gray-500;
}
.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
    color: #757575;
    @apply cursor-not-allowed select-none;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}
.react-calendar__tile {
    max-width: 100%;
    background: none;
    text-align: center;
    line-height: 16px;
    font-size: 0.833em;
    @apply rounded-md p-2.5;
}
.react-calendar__tile:disabled {
    background-color: #f0f0f0;
    color: #ababab;
}
.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
    color: #cdcdcd;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__tile--now {
    @apply bg-main/30 text-gray-600;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    @apply bg-main/40;
}
.react-calendar__tile--hasActive {
    background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}
.react-calendar__tile--active {
    @apply bg-main text-white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    @apply bg-main;
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}


.table-1{
    width: 100%;
    margin-bottom: 1rem;
    vertical-align: top;
}

.table-1 > :not(caption) > * > * {
  padding: 0.75rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

.table-1 > tbody {
  vertical-align: inherit;
}

.table-1 tr:hover {
  background-color: #f8f9fa;
}
.table-1 > thead {
  vertical-align: bottom;  
}



.react-table{
    @apply w-full
}

.react-table thead tr {
    @apply bg-white
}

.react-table thead th {
    @apply font-bold p-0 text-gray-600
}



.react-table tbody {
    @apply  divide-y
}

.react-table tbody:before {
    content:"@";
    display:block;
    line-height:18px;
    text-indent:-99999px;
}

.react-table tbody td {
    @apply text-sm font-normal bg-white py-2 px-3 align-middle
}

.react-table tbody tr{
  @apply transition-all
}

.react-table tbody tr:hover{
  transform: scale(1,1);
  -webkit-transform: scale(1,1);
  -moz-transform: scale(1,1);
  box-shadow: 0 2px 4px #0F223A24;
  -webkit-box-shadow:0 2px 4px #0F223A24;
  -moz-box-shadow: 0 2px 4px #0F223A24;  
}

.mdb-dataTable thead th {
  @apply bg-white
}

.mdb-dataTable tbody td {
  @apply bg-white
}

.react-table-secondary{
    @apply w-full
}

.react-table-secondary thead tr {
 
  @apply bg-[#F2F5FF] 
}

.react-table-secondary tbody {
    @apply  divide-y
}

.react-table-secondary tbody td {
    @apply text-sm font-normal  align-middle
}
